import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { NzSafeAny } from "ng-zorro-antd/core/types";

// current locale is key of the nzAutoTips
// if it is not found, it will be searched again with `default`
export const AutoTips: Record<string, Record<string, string>> = {
  // en: {
  //   required: 'Input is required',
  // },
  // 'zh-cn': {
  //   required: '必填项'
  // },
  default: {
    email: 'Formato de correo no válido',
  }
};

// current locale is key of the MyErrorsOptions
export type MyErrorsOptions = { es: string; en: string; 'zh-cn': string } & Record<string, NzSafeAny>;
export type MyValidationErrors = Record<string, MyErrorsOptions>;

export class MyValidators extends Validators {

  static override required(control: AbstractControl): ValidationErrors | null {
    if (Validators.required(control) === null) {
      return null;
    }
    return { required:
      {
        es: 'Obligatorio',
        en: `Input is required`,
        'zh-cn': `必填项`,
      }
    }
  }

  static override requiredTrue(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      return null;
    }
    return { requiredTrue:
      {
        es: 'Obligatorio',
        en: `Input is must be checked`,
        'zh-cn': `必填项`,
      }
    }
  }

  static override minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.minLength(minLength)(control) === null) {
        return null;
      }
      return { minlength:
        {
          es: `Mínimo ${minLength} caracteres`,
          en: `MinLength is ${minLength}`,
          'zh-cn': `最小长度为 ${minLength}`,
        }
      };
    };
  }

  static override maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.maxLength(maxLength)(control) === null) {
        return null;
      }
      return { maxlength:
        {
          es: `Máximo ${maxLength} caracteres`,
          en: `MaxLength is ${maxLength}`,
          'zh-cn': `最大长度为 ${maxLength}`,
        }
      };
    };
  }

  static override min(min: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.min(min)(control) === null) {
        return null;
      }
      return { min:
        {
          es: `Mínimo ${min}`,
          en: `Minimum ${min}`,
          'zh-cn': `${min}`,
        }
      };
    };
  }

  static override max(max: number): ValidatorFn {
    return (control: AbstractControl): MyValidationErrors | null => {
      if (Validators.max(max)(control) === null) {
        return null;
      }
      return { max:
        {
          es: `Máximo ${max}`,
          en: `Maximum ${max}`,
          'zh-cn': `${max}`,
        }
      };
    };
  }

  static mobile(control: AbstractControl): MyValidationErrors | null {
    const value = control.value;

    if (isEmptyInputValue(value)) {
      return null;
    }

    return isMobile(value)
      ? null
      : { mobile:
        {
          es: `El teléfono es inválido`,
          en: `Mobile phone number is not valid`,
          'zh-cn': `手机号码格式不正确`,
        }
      };
  }
}

function isEmptyInputValue(value: NzSafeAny): boolean {
  return value == null || value.length === 0;
}

function isMobile(value: string): boolean {
  return typeof value === 'string' && /(^3\d{9}$)/.test(value);
}
