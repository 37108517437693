import { FormGroup, FormArray, FormControl } from "@angular/forms";

export const markGroupAsDirty = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach((key) => {
    switch (String(formGroup.get(key)?.constructor.name)) {
      case 'FormGroup':
        markGroupAsDirty(formGroup.get(key) as FormGroup);
        break;
      case 'FormArray':
        markArrayAsDirty(formGroup.get(key) as FormArray);
        break;
      case 'FormControl2':
        markControlAsDirty(formGroup.get(key) as FormControl);
        break;
    }
  });
}

export const markArrayAsDirty = (formArray: FormArray) => {
  formArray.controls.forEach((control) => {
    switch (control.constructor.name) {
      case 'FormGroup':
        markGroupAsDirty(control as FormGroup);
        break;
      case 'FormArray':
        markArrayAsDirty(control as FormArray);
        break;
      case 'FormControl2':
        markControlAsDirty(control as FormControl);
        break;
    }
  });
}

export const markControlAsDirty = (formControl: FormControl) => {
  formControl.markAsDirty();
  formControl.updateValueAndValidity({ onlySelf: true });
}
