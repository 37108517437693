import { TypeWithKey } from "@models/type-with-key.type";

export const regexObj: TypeWithKey<RegExp> = {
  mail: /^[a-zA-Z0-9\u0040.!#$%&'*+/=?^_`{|}~-]*$/,
  numeric: /^[0-9]*$/,
  address: /^[ a-zA-ZáéíóúÁÉÍÓÚ0-9\u00FC\u00f1\u00d1\-\#]*$/,
  alphabeticEnglish: /^[ a-zA-Z]*$/,
  alphabetic: /[ a-zA-ZáéíóúÁÉÍÓÚ\u00FC\u00f1\u00d1]/,
  alphanumeric: /^[ a-zA-ZáéíóúÁÉÍÓÚ0-9\u00FC\u00f1\u00d1]*$/,
  numericWithDots: /^[0-9.]*$/,
  numericWithSpaces: /^[ 0-9]*$/,
  alphabeticWithEnter: /^[ a-zA-ZáéíóúÁÉÍÓÚ\u00FC\u00f1\u00d1\n]*$/,
  alphanumericWithEnter: /^[ a-zA-ZáéíóúÁÉÍÓÚ0-9\u00FC\u00f1\u00d1\n]*$/,
}

export const maskCustomPatters = {
  'E': {pattern: new RegExp(regexObj["alphabetic"])},
  'N': {pattern: new RegExp(regexObj["alphanumeric"])},
  'M': {pattern: new RegExp(regexObj["mail"])}
}
