import { WritableSignal, signal } from "@angular/core";
import { AsyncValidatorFn, FormControl, FormControlState, ValidatorFn } from "@angular/forms";

interface MyFormControlState<T> extends FormControlState<T> {
  nullable?: boolean;
}

export const mC = <T>(
  value: T | MyFormControlState<T>,
  validator?: ValidatorFn | ValidatorFn[] | null | undefined,
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null | undefined
): FormControl<T> => {

  const nullable: WritableSignal<boolean> = signal<boolean>(false);

  if( typeof value == 'object' ){
    (value as MyFormControlState<T>).nullable && nullable.set(true);

    delete (value as MyFormControlState<T>).nullable;
  }

  return new FormControl<T>(value, {validators: validator, nonNullable: !nullable() }, asyncValidator) as FormControl<T>;
};
